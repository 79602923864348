<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->
        <!-- Data Pimpinan -->
        <b-card>
            <div class="pl-4 py-2 border rounded shadow-sm">
                <b-button variant="primary" class="px-4" to="/admin/wakil/list" pill>
                    <i class="fa fa-arrow-circle-left pr-2"></i> 
                    Kembali
                </b-button>
            </div>
            <div class="py-2">
                <b-table-simple bordered small responsive>
                    <b-th colspan="6">
                        <h5 class="text-center">Detail Wakil</h5>
                    </b-th>
                    <b-tr>
                        <b-td width="200px">Nama</b-td>
                        <b-td class="font-weight-bold" v-if="detailWakil">{{ detailWakil.nama }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>NIK</b-td>
                        <b-td colspan="5" class="font-weight-bold">{{ detailWakil.nik }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Jabatan</b-td>
                        <b-td colspan="5" v-if="detailWakil.jabatan">{{ detailWakil.jabatan }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Alamat</b-td>
                        <b-td colspan="5">
                            {{ detailWakil.alamat }} <br>
                            <!-- {{ detailWakil.village.name }} <br>
                            KEC. {{ detailWakil.village.district.name }} <br>
                            {{ detailWakil.village.district.city.name }} <br>
                            PROV. {{ detailWakil.village.district.city.province.name }} <br>
                            {{ detailWakil.kodepos }} -->

                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Kontak</b-td>
                        <b-td colspan="5">
                            <div v-if="detailWakil.nomp">
                                No. Hp: {{ detailWakil.nomp }}
                            </div>
                            <div v-if="detailWakil.notelepon ">
                                No. Telepon: {{ detailWakil.notelepon }}
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>Email</b-td>
                        <b-td colspan="5">{{ detailWakil.email }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="5" height="25px"></b-td>
                    </b-tr>
                    <b-th colspan="5">
                        <h4>SURAT KETETAPAN</h4>
                    </b-th>
                    <b-tr>
                        <b-td>
                            Nomor Surat Ketetapan
                        </b-td>
                        <b-td>
                            {{ detailWakil.no_sk }}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>
                           Tanggal Surat Ketetapan
                        </b-td>
                        <b-td>
                            {{ detailWakil.tgl_sk }}
                        </b-td>
                    </b-tr>
                    <!-- <b-tr>
                        <b-td>
                            NPWPD
                        </b-td>
                        <b-td colspan="5" class="font-weight-bold" v-if="detailWakil.npwpd.nama">{{ detailWakil.npwpd.nama }}</b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-td>Status</b-td>
                        <b-td colspan="5" class="h5 pb-2">
                            <span v-if="detailWakil.aktif === 1" class="label label-success">Aktif</span>
                            <span v-if="detailWakil.aktif === 0" class="label label-danger">Tidak Aktif</span>
                        </b-td>
                    </b-tr>
                </b-table-simple>
            </div>
        </b-card>
        <!-- End Data Pimpinan -->
    </div>
</template>

<script>
import Logo from '@/assets/images/logoSumut.png'
import axios from 'axios'
import HeadTitle from '@/components/header/HRetribusi.vue'

export default {
    components: {
        HeadTitle
    },
    data () {
        return {
            imageLogo: Logo,
            detailWakil: null
        }
    },
    created () {
        axios.get('/api/wajib-pajak/npwpd-wakil/' + this.$route.params.id).then(response => {
            this.detailWakil = response.data.data
            // console.log(this.detailWakil)
        }).catch(error => {
            console.log(error)
            if (error.response.status === 401) {
                localStorage.removeItem("EP_token");
                this.$router.push({name: 'Login'})
            }
        })
    }
}
</script>